import React,  { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import VendegLista from './regisztracio/VendegLista';
import OnlineFizetesPayPal from './regisztracio/OnlineFizetesPayPal';
import OnlineFizetesStripe from './regisztracio/OnlineFizetesStripe';

import JegyLista from './regisztracio/JegyLista';

import SzamlazasiAdatokDisplay from './regisztracio/SzamlazasiAdatokDisplay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export default function Regisztracio({betoltottMegrendelesData, vevo, setVevo, ...props}) {
    const [rendezveny, setRendezveny] = useState(undefined);
    const [jegyek_mentve, setJegyek_mentve] = useState([]);
    const [fizetes_mentes_gomb_helyett_karika, setFizetes_mentes_gomb_helyett_karika] = useState(false);
    const [adat1Text, setAdat1Text] = useState("");
    const [adat2Text, setAdat2Text] = useState("");
    const [adat3Text, setAdat3Text] = useState("");
    

    let navigate = useNavigate();
    //let location = useLocation();

    useEffect(() => {
        /*
        console.log("esuEffect regiszt")
        console.log(betoltottMegrendelesData);
        */

    }, [fizetes_mentes_gomb_helyett_karika])

    useEffect(() => {        
        if (props.rendezvenyID != undefined) {
            props.callAPI("General.getRendezveny", {rendezvenyID: props.rendezvenyID}, function(resp){
                setRendezveny(resp.data.rendezveny)
                // Ezután még megnézzük, hogy véletlenül nem egy kifizetett (vagy fizetetlen) megrendelés-e ez már!
                if (betoltottMegrendelesData != undefined) {
                    console.log("ez egy betöltött megrendelés")
                    console.log(betoltottMegrendelesData)
                    setJegyek_mentve(betoltottMegrendelesData.jegyek)
                }
            });
        }
    }, [])

    function minden_adat_kitoltve(){
        var kitoltve = true;
        
        if (rendezveny.Adat1kell == 1 && adat1Text == ''){
            kitoltve = false;
        }
        
        if (rendezveny.Adat2kell == 1 && adat2Text == ''){
            kitoltve = false;
        }
        if (rendezveny.Adat3kell == 1 && adat3Text == ''){
            kitoltve = false;
        }
        
        return kitoltve;
    }

    function onChange_adat(adatNumber, event){
        if (adatNumber == 1){
            setAdat1Text(event.currentTarget.value);
        }
        if (adatNumber == 2){
            setAdat2Text(event.currentTarget.value);
        }
        if (adatNumber == 3){
            setAdat3Text(event.currentTarget.value);
        }
        minden_adat_kitoltve()
    }

    function ez_egy_lezart_kifizetett_megrendeles(){
        if (betoltottMegrendelesData != undefined) {
            if (betoltottMegrendelesData.Fizetessikeres == 1){
                return true;
            }else{
                // fizetés sikertelen, újra próbálhatja
                return false;
            }
        }else{
            // folyamatban lévő megrendelés, nincs még mentve
            return false;
        }
    }

    function van_e_eleg_jegy(){
        console.log("Van-e elég jegy: " + jegyek_mentve.length)
        return jegyek_mentve.length > 0
    }

    function onClick_vissza_a_rendezvenyvalasztashoz(){
        if (props.getCommInProgress()){
            
        }else{
            var torolheto = false;
            if (rendezveny.Vendegeknevszerint == 1){
                let vanLefoglaltIdopont = false;
                for (var i in jegyek_mentve){
                    if (jegyek_mentve[i].rendezvenyReszek.length > 0){
                        vanLefoglaltIdopont = true;
                    }
                }
                if (vanLefoglaltIdopont){
                    torolheto = window.confirm("Ha másik rendezvényt választ, akkor a vendégek nevét is újra meg kell adnia!\n\nFolytatja?")
                }else{
                    torolheto = true;
                }
            }else{
                torolheto = true;
            }

            if (torolheto){
                var data = {};
                if (betoltottMegrendelesData != undefined){
                    data.megrendelesID = betoltottMegrendelesData.Id;
                }
                data.vevoID = vevo.Id
                props.callAPI("General.megrendelesTorleseHaVanEsGetLoginURL", data, function(resp){
                    window.location = resp.data.loginURL;
                });
            }
        }
    }

    function regisztracio_mentese(onComplete){
        var data = {
            'regiMegrendeles': betoltottMegrendelesData,
            'rendezvenyID': rendezveny.Id,
            'jegyek': jegyek_mentve,
            'vevo': vevo,
            'adatTextArray': {1: adat1Text, 2: adat2Text, 3: adat3Text},
            'fizetesSikeres': van_e_olyan_mentett_jegy_ami_penzbe_kerul() ? 0 : 1     // ha ingyen van, akkor fizetettre állítjuk
        }
        props.callAPI("General.saveMegrendeles", data, function(resp){
            onComplete(resp.data.megrendelesID, resp.data.megrendelesURL)
        });
    }

    function onSave_vendegek_vagy_jegyek(ujJegyek){
        setJegyek_mentve(ujJegyek);
    }

    function onSaveVevo(inVevo){
        setVevo(inVevo)  // kívül menti
    }

    function szamlazasi_adatok_megvannak(){
        var megvannak = true;
        if (vevo.Telepules == '') {megvannak = false}
        if (vevo.Ceg == undefined) {megvannak = false}
        if (vevo.Irsz == '') {megvannak = false}
        if (vevo.Cim == '') {megvannak = false}
        if (vevo.Szemelynev == '') {megvannak = false}
        return megvannak;
    }

    function van_e_olyan_mentett_jegy_ami_penzbe_kerul(){
        var jegyek_vizsgalni= jegyek_mentve;
        var ar_osszesen = 0;
        for (var i in jegyek_vizsgalni){
            var jegy = jegyek_vizsgalni[i];
            ar_osszesen += jegy.jegyTipus.Arnetto;
        }    
        return (ar_osszesen > 0)
    }

    function onClick_ingyenes_regisztracio_mentese(){
        setFizetes_mentes_gomb_helyett_karika(true)
        let onc = function(megrendeles_id, megrendeles_url){
            props.callAPI("General.sendMegrendelesVisszaigazolasEmail", {megrendelesID: megrendeles_id}, function(resp){
                if (resp.data.emailResult == 'ok'){
                    navigate(megrendeles_url);
                }
            });
        }
        regisztracio_mentese(onc)
    }

    function onClick_btnStripe(){
        regisztracio_mentese(function(megrendeles_id){
            setFizetes_mentes_gomb_helyett_karika(true)
            props.callAPI("General.getStripeURL", {megrendelesID: megrendeles_id}, function(resp){
                //setFizetes_mentes_gomb_helyett_karika(false)
                window.location = resp.data.stripeURL
            });    
        });
    }


    if (rendezveny == undefined){
        return (<div />);
    }else{
    return (
        <Container className="regisztracio">
            <br />
            <h2>Regisztráció</h2>
            <div className="regisztracio_fejlec rendezveny">
                <div className="regisztracio_inside">
                    <FontAwesomeIcon icon="fa-calendar" size='2x' color="#609090" />
                </div>
                <div className="regisztracio_inside">
                    <span>A regisztrációt végzi: </span><span className="bold">{vevo['Szemelynev']} ({vevo['Email']})</span>
                    <br />
                    <span>Rendezvény: </span><span className="bold">{rendezveny['Cim']} - {rendezveny['Alcim']}</span>
                </div>
                <br />
                <br />
                    
                        <Button
                            className="regisztracio_inside_button"
                            variant="default"
                            onClick={onClick_vissza_a_rendezvenyvalasztashoz.bind(this)}>Másik rendezvényt választok</Button>

                    
                
            </div>

{!ez_egy_lezart_kifizetett_megrendeles() ?
<div>
            {rendezveny.Vendegeknevszerint == 1 ? 
                <VendegLista rendezveny={rendezveny} jegyek={jegyek_mentve} onSave={onSave_vendegek_vagy_jegyek.bind(this)} />
                :
                <JegyLista rendezveny={rendezveny} jegyek={jegyek_mentve} onSave={onSave_vendegek_vagy_jegyek} />
            }

                        <br />
                        <hr />
                        {(rendezveny.Adat1kell == 1) ?
                            <div className="utasitas">
                                Adja meg az alábbi adatokat is!
                            </div>
                            :
                            undefined
                        }

                        {(rendezveny.Adat1kell == 1) ?
                            <div className="adatHolder">
                                <div className="adatNev">{rendezveny.Adat1neve}</div>
                                <input type="text" className="input" onChange={onChange_adat.bind(this, 1)} value={adat1Text} />
                                <div className="adatMegjegyzes">{rendezveny.Adat1megjegyzes}</div>
                            </div>
                        :
                            undefined
                        }

                        {(rendezveny.Adat2kell == 1) ?
                            <div className="adatHolder">
                                <div className="adatNev">{rendezveny.Adat2neve}</div>
                                <input type="text" className="input" onChange={onChange_adat.bind(this, 2)} value={adat2Text} />
                                <div className="adatMegjegyzes">{rendezveny.Adat2megjegyzes}</div>
                            </div>
                        :
                            undefined
                        }


            {van_e_olyan_mentett_jegy_ami_penzbe_kerul() ? 
                <div className="regisztracio_inside">
                    {/* -------------------------------------------------------------------------- */}
                    {/* ---------------------------- PÉNZES JEGYEK ------------------------------- */}
                    {/* -------------------------------------------------------------------------- */}
                    <SzamlazasiAdatokDisplay onSave={onSaveVevo} vevo={vevo} callAPI={props.callAPI} />
                    
                    {!szamlazasi_adatok_megvannak() ? 
                        <div className="figy_uzenet"><br />A számla kiállításához meg kell adnia a számlázási adatokat!</div>
                    :
                        <div>
                            {!van_e_eleg_jegy() ?
                                undefined // nincs elég jegy
                            :
                            <div>
                                {fizetes_mentes_gomb_helyett_karika ?
                                    <div className="btnStripe"><br />Várjon...</div>
                                :
                                <div className="btnStripe" onClick={onClick_btnStripe}>
                                    <div className="text">Fizetés<br />bankkártyával</div>
                                    <img className="icon" src={"/img/stripe_icon.png"} />
                                    
                                </div>
                                }
                            </div>
                            }
                        </div>
                    }
                </div>
                :
                <div>
                    {/* -------------------------------------------------------------------------- */}
                    {/* ---------------------------- INGYENES JEGYEK ----------------------------- */}
                    {/* -------------------------------------------------------------------------- */}
                    <br clear="all" />
                    {(van_e_eleg_jegy() && (minden_adat_kitoltve())) ?
                        <div className="reg_kozepre">
                            {!fizetes_mentes_gomb_helyett_karika ?
                                <div className="reg_kozepre">
                                    <div >Kérjük, tekintse át még egyszer, hogy jól adta-e meg az adatokat!</div>
                                    <div >Ha hibát talál, a <b><i>Vendég törlése</i></b> vagy a <b><i>Másik rendezvényt választok</i></b> gombbal visszaléphet.</div>
                                    <Button onClick={onClick_ingyenes_regisztracio_mentese.bind(this)}>Elküldöm a regisztrációt</Button>
                                </div>
                            :
                                <div className="reg_kozepre">
                                    Kérjük, várjon...
                                </div>
                            }
                        </div>
                        : undefined
                    }
                </div>
            }
</div>
:
<div>
    nem kiszámlázott, vagy nem kifizetett megrendelés
</div>}
        </Container>
    )
    }
}
